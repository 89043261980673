import {
  callGetApi,
  callPostApi,
  // callPostFormApi,
  callPostApiWithPagination,
} from '../api-common';
import {
  Currency,
  defaultCurrencySettings,
} from '@/reducers/product-management';
import { TakeoffCostProps } from './components/Takeoff-cost';
import { Category, Template } from './drawing-takeoff-view';

type ExportRequestParams = {
  project_id: string;
  drawing_id: string;
  version_id?: string;
  report_type: string;
  utc_offset: number;
  template_id?: string;
  content_type?: 'application/pdf';
};

export type ExportResult = {
  name: string;
  read_url: string;
};

type OrgTakeoffSettingResult = {
  create_time: Date;
  create_user: string;
  creator_email: string;
  currency: Currency;
  deleted: boolean;
  id: string;
  org_id: string;
  update_time: Date;
  uuid: string;
  min_price: null | number;
};

function getDrawingTakeoff(params: {
  project_id: string;
  drawing_id: string;
  version_id: string;
}) {
  return callPostApi('/user/service/takeoff/get/', params) as Promise<{
    categories: Category[];
    takeoff: TakeoffCostProps['takeoff'];
  }>;
}

function queryReportTemplates(params: {
  project_id: string;
  include_draft?: boolean;
  page_size: number;
}) {
  return callPostApi(
    '/user/service/report_template/query/',
    params
  ) as Promise<{ result: Template[] }>;
}

function createOrUpdateOrgTakeoffSetting(
  params:
    | {
        currency: Currency;
        uuid: string;
      }
    | {
        uuid: string;
        min_price: number | null;
      }
) {
  return callPostApi(
    '/user/service/flex_object/takeoff_setting/add_update/',
    params
  ) as Promise<typeof defaultCurrencySettings>;
}

export function takeoffExportPdf(params: ExportRequestParams) {
  return callPostApi(
    '/user/service/takeoff/export_pdf/v2/',
    params
  ) as Promise<ExportResult>;
}

function takeoffExportXlsx(params: ExportRequestParams) {
  return callPostApi(
    '/user/service/takeoff/export_xls/v2/',
    params
  ) as Promise<ExportResult>;
}

function queryOrgTakeoffSetting() {
  return callPostApiWithPagination(
    '/user/service/flex_object/takeoff_setting/query/',
    {},
    -1,
    -1
  ) as Promise<{
    count: number;
    result: OrgTakeoffSettingResult[];
  }>;
}

function sendInvoiceToSalesForce(params: {
  project_id: string;
  drawing_version_id: string;
}) {
  return callPostApi('/user/service/sf/drawing/takeoff/push/', params);
}

function getSalesforceConfig() {
  return callGetApi('user/service/settings/sf/get/');
}

function getI360Config() {
  return callGetApi('/user/service/settings/i360/get/');
}

function sendInvoiceToI360(params: {
  project_id: string;
  drawing_version_id: string;
}) {
  return callPostApi('/user/service/i360/drawing/takeoff/push/', params);
}

function pushItemsToPlatform(params: {
  project_id: string;
  drawing_version_id: string;
  push_type: string;
}) {
  return callPostApi('/user/service/intuit/push_items/', params);
}

export default {
  getDrawingTakeoff,
  getSalesforceConfig,
  getI360Config,
  sendInvoiceToSalesForce,
  sendInvoiceToI360,
  pushItemsToPlatform,
  queryReportTemplates,
  createOrUpdateOrgTakeoffSetting,
  queryOrgTakeoffSetting,
  takeoffExportPdf,
  takeoffExportXlsx,
};
