import Icon from '@ant-design/icons';
import { ReactComponent as referralIcon } from '@/assets/icons/referral-url.svg';
import { ReactComponent as launchIcon } from '@/assets/icons/launch.svg';
import { User } from '@/reducers/product-management';
import tracker from '@/tracker';

const AFFILIATE_LINK = 'https://affiliate.arcsite.com/';
const BOOK_DEMO_LINK = 'https://www.arcsite.com/book-demo-inapp';

const MATCHED_USE_CASES = [
  'Capture Site Data',
  'Perform Material Takeoffs',
  'Build Estimates and Proposals',
];
const MATCHED_INDUSTRY = [
  'Basement Waterproofing',
  'Building Inspection',
  'Decks & Patios',
  'Fencing',
  'Fire Inspection',
  'Foundation Repair',
  'Gutters',
  'Landscape & Irrigation',
  'Pest Control',
  'Site Audits / Inspection',
  'Turf / Synthetic Turf',
  'Architecture',
  'Architectural Engineering',
  'Building Controls',
  'Civil Engineering',
  'Concrete',
  'Electrical / Lighting',
  'Electrical Engineering',
  'Energy & Solar',
  'Engineering & Mechanical',
  'Flooring',
  'HVAC',
  'Landscape Designer',
  'Maintenance',
  'Mechanical/Mechanical Engineering',
  'Plumbing',
  'Roofing',
  'Security / Security Inspection',
  'Structural Engineering',
  'Sub Contractor',
  'Telecom',
];

export const USER_CAMPAIGN_MAP = {
  BookDemo: {
    text: 'Book Demo',
    link: BOOK_DEMO_LINK,
    icon: launchIcon,
  },
  // RecommendArcSite: {
  //   text: 'Recommend ArcSite',
  //   link: recommendArcsiteLink,
  //   icon: peopleIcon,
  // },
  BecomeAnAffiliate: {
    text: 'Become an Affiliate',
    link: AFFILIATE_LINK,
    icon: referralIcon,
  },
} as const;

export default function ArcSiteCampaign({ user }: { user: User }) {
  const { text, icon, link } = useArcSiteCampaign(user);
  return (
    <a
      className="mr-4 hidden sm:block"
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        tracker.logEvent('web_activity_button_click', { activity: text });
      }}
    >
      <div className="bg-[#E3571C] text-xs px-2.5 py-2 text-white rounded font-semibold flex items-center">
        <Icon component={icon} className="text-16" />
        <span className="whitespace-nowrap ml-2 hidden sm:block">{text}</span>
      </div>
    </a>
  );
}

const useArcSiteCampaign = (user: User) => {
  const tierMatched =
    user.default_org_purchase_type == 'TRIAL' ||
    user.default_org_user_level === 'BASIC' ||
    user.default_org_user_level === 'PRO';

  const useCaseMatched = MATCHED_USE_CASES.some(useCase =>
    user.use_cases.includes(useCase)
  );
  const industryMatched = MATCHED_INDUSTRY.includes(user.industry || '');

  if (tierMatched && (useCaseMatched || industryMatched)) {
    return USER_CAMPAIGN_MAP.BookDemo;
  }
  return USER_CAMPAIGN_MAP.BecomeAnAffiliate;
};
