import { CSSProperties, useEffect, useState } from 'react';
import * as React from 'react';
import { Dropdown, Menu, MenuItemProps, message } from 'antd';
import Icon, { DownOutlined, CloseOutlined } from '@ant-design/icons';
import { showAdmin } from '@/utils/user';
import { css } from '@emotion/react';
import companyLogoIcon from '@/assets/icons/company-logo.svg';
import { ReactComponent as upgrade } from '@/assets/images/upgrade.svg';
import { ReactComponent as account } from '@/assets/images/account.svg';
import { ReactComponent as logoutIcon } from '@/assets/images/logout.svg';
import { ReactComponent as downloadapp } from '@/assets/images/download.svg';
import { ReactComponent as help } from '@/home/images/svg/help.svg';
import { ReactComponent as help2 } from '@/assets/icons/help.svg';
import { ReactComponent as referralUrl } from '@/assets/icons/referral-url.svg';
import { ReactComponent as referralOrange } from '@/assets/icons/referral-orange.svg';
import { ReactComponent as vector } from '@/assets/icons/vector.svg';
import { useLogout } from '@/auth';
import tracker from '@/tracker';
import { useUser } from '@/hooks';
import {
  getBillingSubscription,
  shouldFetchSubscription,
} from '../Subscribe/utils';
import { getEverLoginApp } from './api';
import { Link } from 'react-router-dom';
import DownloadModal from '@/components/modal/DownloadModal';
import { calculateRemainingTrialDays } from '@arcsite/utils';
import ArcSiteCampaign from './campaign';
// import { useQuery } from 'react-query';
// import { getCompanyInfo } from '@/api';
// import { queryClient } from '@/query_client';

// queryClient.prefetchQuery('companyInfo', getCompanyInfo);

type iconDataType = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  style: CSSProperties;
};

export function ExpiredDes({
  expiredStatus,
  expiredDays,
}: {
  expiredDays?: string;
  expiredStatus: 'expired' | 'expire_soon' | 'not_expired_soon';
}) {
  let getExpiredDes = (): {
    className: string;
    description: string;
    brief: string;
  } => {
    switch (expiredStatus) {
      case 'not_expired_soon':
        return {
          className: 'bg-[#E37612]',
          description: `Trial - ${expiredDays} days left`,
          brief: 'Trial',
        };
      case 'expire_soon':
        return {
          className: 'bg-[#398AE7]',
          description: `Subscription will expire in ${expiredDays} days`,
          brief: '',
        };
      case 'expired':
        return {
          className: 'bg-[#6E6E6E]',
          description: `Subscription Expired`,
          brief: 'Expired',
        };
      default:
        return {
          className: 'bg-[#E37612]',
          description: `Trial - ${expiredDays} days left`,
          brief: 'Trial',
        };
    }
  };
  let { className, description, brief } = getExpiredDes();

  return (
    <div
      className={`${className} mx-auto text-white rounded px-1 py-0.5 mt-1 w-fit whitespace-nowrap`}
    >
      <span className="inline-block sm:hidden">{brief}</span>
      <span className="hidden sm:inline-block">{description}</span>
    </div>
  );
}

export const MenuItem = ({
  key,
  link,
  inner,
  iconData,
  target,
  menuText,
  ...props
}: {
  key: number;
  link?: string;
  inner?: boolean;
  target?: '_self' | 'blank';
  iconData: iconDataType;
  menuText: string;
} & MenuItemProps) => {
  const content = (
    <>
      <Icon component={iconData.icon} style={iconData.style} />
      <span className="ml-2.5 text-black-85">{menuText}</span>
    </>
  );
  return (
    <Menu.Item
      key={key}
      {...props}
      css={css`
        height: 48px;
        padding: 0 18px;
        border-bottom: 1px solid #ededed;
        font-weight: 500;
      `}
    >
      {inner ? (
        <Link to={link!} className="flex items-center">
          {content}
        </Link>
      ) : (
        <a href={link} target={target} className="flex items-center">
          {content}
        </a>
      )}
    </Menu.Item>
  );
};

const IntercomWebWidget = () => {
  const onClick = () => {
    if (!window.Intercom) {
      message.error(
        'The help center is not ready yet, please refresh the page and try again'
      );
      return;
    }
    window.Intercom('show');
  };

  return (
    <div
      onClick={onClick}
      className="shrink-0 flex items-center justify-center h-8 w-8 sm:h-12 sm:w-12 rounded box-content cursor-pointer hover:bg-gray-f5f5f5"
    >
      <Icon component={help} className="text-24" />
    </div>
  );
};

const DownloadButton = ({ onClick }: { onClick: () => void }) => {
  const [hiddenDownload, setHiddenDownload] = useState(false);
  const [isEverLoginApp, setEverLoginApp] = useState(true);

  useEffect(() => {
    getEverLoginApp()
      .then(res => {
        setEverLoginApp(res.value);
      })
      .catch(() => {
        setEverLoginApp(false);
      });
  }, []);

  return (
    <>
      {!isEverLoginApp && !hiddenDownload ? (
        <div
          className="bg-gray-f0f0f0 items-center py-2 px-3 gap-2 rounded mr-4 cursor-pointer hidden sm:flex"
          onClick={onClick}
        >
          <Icon component={vector} className="text-16" />
          <span className="text-blue-398AE7 whitespace-nowrap hidden sm:inline-block">
            Download App
          </span>
          <CloseOutlined
            onClick={e => {
              e.stopPropagation();
              setHiddenDownload(true);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export const UserName = ({ username }: { username: string }) => {
  return (
    <>
      <span className="hidden sm:inline-block">{username}</span>
      <span className="inline-block sm:hidden bg-[#E3571C] h-8 w-8 rounded-full text-white leading-8">
        {username.slice(0, 1).toUpperCase()}
      </span>
    </>
  );
};

const CompanyLogo = () => {
  const user = useUser();
  return (
    <Link to="/admin/manage_accnt" className="hidden sm:block">
      <div className="relative border-l-1 border-black-10 cursor-pointer">
        <span
          className={`relative p-3 inline-block hover:after:content-['Edit'] hover:after:absolute hover:after:left-0 hover:after:top-0 
        hover:after:w-full hover:after:h-full hover:after:flex hover:after:items-center hover:after:justify-center 
        hover:after:bg-[rgba(0,0,0,0.6)] hover:after:text-white-96`}
        >
          <img
            height="32"
            className="object-contain"
            src={user?.company_logo_url || companyLogoIcon}
            alt="company logo"
          />
        </span>
      </div>
    </Link>
  );
};

const MainMenu = () => {
  const user = useUser();
  const isAdmin = showAdmin(user);
  const isCompany = user?.default_org_type == 'COMPANY';
  const [cancelled, setCancelled] = useState(false);
  const [downloadVisiable, setDownloadVisiable] = useState(false);
  const logout = useLogout();
  const menu = (
    <Menu
      css={css`
        .ant-dropdown-menu-item {
          font-size: 13px;
        }
        .ant-dropdown-menu-item:hover {
          background-color: #e3eefe;
        }
      `}
      style={{
        width: 256,
        background: '#F6FBFF',
        padding: 0,
        right: 0,
        position: 'absolute',
      }}
    >
      <MenuItem
        key={-10}
        target="blank"
        link={'https://affiliate.arcsite.com/'}
        className="text-16 text-[#E3571C] flex sm:!hidden"
        iconData={{
          icon: referralOrange,
          style: { fontSize: 16 },
        }}
        menuText="Become an affiliate"
      />
      <MenuItem
        key={-9}
        onClick={() => {
          window.Intercom && window.Intercom('show');
        }}
        className="text-24 flex sm:!hidden"
        iconData={{
          icon: help2,
          style: { fontSize: 16 },
        }}
        menuText="Get Support"
      />
      <MenuItem
        inner={true}
        key={1}
        link={'/app/subscribe'}
        onClick={() => {
          tracker.logEvent('web_open_subscriptions');
        }}
        iconData={{
          icon: upgrade,
          style: { fontSize: 16 },
        }}
        menuText="Subscription"
      />
      <MenuItem
        inner={true}
        key={2}
        link={'/app/account-info'}
        onClick={() => {
          tracker.logEvent('web_open_profile');
        }}
        iconData={{
          icon: account,
          style: { fontSize: 16 },
        }}
        menuText="Profile & Settings"
      />
      <MenuItem
        key={3}
        iconData={{
          icon: downloadapp,
          style: { fontSize: 16 },
        }}
        onClick={() => showDownloadModal()}
        menuText="Download App"
      />
      <MenuItem
        key={4}
        onClick={() => {
          logout();
        }}
        iconData={{
          icon: logoutIcon,
          style: { fontSize: 16 },
        }}
        menuText="Logout"
      />
    </Menu>
  );
  useEffect(() => {
    if (shouldFetchSubscription(user)) {
      getBillingSubscription().then(subscription => {
        if (subscription) {
          setCancelled(subscription.current?.cancel_at != null);
        }
      });
    }
  }, [user]);

  if (!user) {
    return null;
  }

  const expiredDays = calculateRemainingTrialDays(
    user.default_org_subs_expire_time
  ).toString();

  const showDownloadModal = () => {
    setDownloadVisiable(true);
  };

  return (
    <div className="h-full">
      <div className="flex items-center h-full sm:w-auto">
        <DownloadButton onClick={showDownloadModal} />
        <ArcSiteCampaign user={user} />
        {isAdmin && isCompany && <CompanyLogo />}
        <Dropdown overlay={menu} placement={'bottomLeft'}>
          <div className="h-full flex items-center px-3 sm:px-4 relative border-l-1 border-black-10 hover:bg-gray-f5f5f5 mr-4 sm:mr-0">
            <a className="flex items-center self-stretch">
              <span className="inline-flex flex-col text-center items-center">
                <UserName username={user.username || ''} />
                {user.expired && <ExpiredDes expiredStatus="expired" />}
                {cancelled && (
                  <ExpiredDes
                    expiredStatus="expire_soon"
                    expiredDays={expiredDays}
                  />
                )}
                {user.default_org_purchase_type == 'TRIAL' &&
                  !user.expired_without_extension && (
                    <ExpiredDes
                      expiredDays={expiredDays}
                      expiredStatus="not_expired_soon"
                    />
                  )}
              </span>
              <DownOutlined className="ml-3" />
            </a>
          </div>
        </Dropdown>
        <div className="items-center self-stretch px-2 sm:px-3 border-l border-black-10 hidden sm:flex">
          <IntercomWebWidget />
        </div>
      </div>
      <DownloadModal
        downloadVisiable={downloadVisiable}
        setDownloadVisiable={setDownloadVisiable}
      ></DownloadModal>
    </div>
  );
};

export default MainMenu;
